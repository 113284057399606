import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from 'constants/ThemeConstant';
import env from 'configs/EnvironmentConfig';
import { ThemeState } from "models";

export const APP_NAME = 'Rubitcube User Portal';
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const SLACK_APP_BASE_URL = env.SLACK_APP_ENDPOINT_URL;
export const ZOHO_APP_BASE_URL = env.ZOHO_APP_ENDPOINT_URL;
export const HUBSPOT_APP_BASE_URL = env.HUBSPOT_APP_ENDPOINT_URL;

export const WHATSAPP_APP_ID = env.WHATSAPP_META_APP_ID;
export const WHATSAPP_CONFIG_ID = env.WHATSAPP_META_CONFIG_ID;

export const THEME_CONFIG: ThemeState = {
    navCollapsed: false,
    sideNavTheme: SIDE_NAV_LIGHT,
    locale: 'en',
    navType: NAV_TYPE_SIDE,
    topNavColor: '#3e82f7',
    headerNavColor: '',
    mobileNav: false,
    currentTheme: "light",
    direction: DIR_LTR
};

