
import React, { useRef, useState } from 'react';
import { MailOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, notification, Row } from "antd";
import ReCaptchaV2 from 'react-google-recaptcha';
import { requestForgotPasswordTokenService } from "services/auth/UserService";

const ForgotPasswordForm = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const captcha = useRef<ReCaptchaV2 | null>(null);

  const onSuccess = () => {
    const notificationParam = {
      message: 'Password reset link send to your email.',
      description: ''
    };
    notification.success(notificationParam);
    setLoading(false);
    form.resetFields();
  };

  const onError = (message: string) => {
    const notificationParam = {
      message,
      description: ''
    };
    notification.error(notificationParam);
    setLoading(false);
    form.resetFields();
  };

  const onSubmit = async (values: { username: string }) => {
    setLoading(true);
    try {
      const response = await requestForgotPasswordTokenService({ email: values.username });
      if (response.detail) {
        onSuccess();
      } else {
        onError('Password reset request failed');
      }
    } catch (e: any) {
      onError(e.message);
    }
  };

  return (
    <Col xs={24} sm={24} md={24} lg={24}>
      <Form
        form={form}
        name="forgotPasswordForm"
        onFinish={onSubmit}
      >
        <Row>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Form.Item
              name="username"
              label="Email"
              rules={[
                {
                  required: true,
                  message: 'Please enter your email',
                },
                {
                  type: 'email',
                  message: 'Please enter a valid email!'
                }
              ]}>
              <Input prefix={<MailOutlined className="text-primary" />} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={18} sm={18} md={18} lg={18}>
            <Form.Item
              name="recaptcha"
              rules={[
                {
                  required: true,
                  message: 'Please verify recaptcha',
                }
              ]}>
              <ReCaptchaV2 sitekey="6Lf4K-MUAAAAAKIuYTb0D83z99biKxuYXFnltIR5" ref={captcha}
                onChange={(token: string | null) => form.setFieldsValue({ recaptcha: token })} onExpired={() => form.setFieldsValue({ recaptcha: '' })} />
            </Form.Item>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6}>
            <div className="text-right mt-4">
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Col>
  );
};


export default ForgotPasswordForm;
