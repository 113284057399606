import React, { useRef, useState} from 'react';
import { Button, Form, Input } from 'antd';
import ReCaptchaV2 from 'react-google-recaptcha';
import { validateSignupEmailService, resendNewAccountEmailService } from 'services/auth/RegisterService';
import { SignupEmailValidateResponse } from 'models/responses';
import { useNavigate } from 'react-router-dom';
import { handleApiError } from 'utils/helper';

const ValidateEmailForm = (props: { onclickNext: (nextStep: number, email: string) => void }) => {
  const [form] = Form.useForm();
  const captcha = useRef<ReCaptchaV2 | null>(null);

  const navigate = useNavigate();
  const { onclickNext } = props;
  const [loading, setLoading] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [emailID, setEmailID] = useState<string>();
  const [isSpamBlocked, setIsSpamBlocked] = useState(false);
  const findNextStep = (response: SignupEmailValidateResponse) => {
    if (response.blacklisted) {
      return 2;
    }
    if (response.dropped_user) {
      return 0;
    }
    return 1;
  };
  const clearCaptcha = () => {
    captcha?.current?.reset();
    form.setFieldsValue({ recaptcha: '' });
  };

  const onErrorValidate = (error: unknown) => {
    setLoading(false);
    handleApiError(error);
    clearCaptcha();
  };
  const onErrorResend = (error: unknown) => {
    setLoading(false);
    handleApiError(error);
    clearCaptcha();
  };
  const resendEmail = async (values: { email: string }) => {
    try {
      const response = await resendNewAccountEmailService({ email: values.email });
      setLoading(false);
      if (response) {
        navigate('/signup/verify-email');
      } else {
        onErrorResend({ message: 'Failed to validate email', status: 0 });
      }
    } catch (error) {
      onErrorResend(error);

    }
  };


  const onValidate = async (values: { email: string }) => {
    setLoading(true);
    try {
      const response: SignupEmailValidateResponse = await validateSignupEmailService(values);
      const nextStep = findNextStep(response);
      if (nextStep === 0) {
        await resendEmail(values);
      } else if (nextStep === 2) {
        if (response.blacklisted_type==="SOFT") {
          setIsBlocked(true);
          setIsSpamBlocked(false);
        }
        else {
          setEmailID((values.email).split("@")[1]);
          setIsSpamBlocked(true);
          setIsBlocked(false);
        }
        setLoading(false);
      } else {
        setLoading(false);
        onclickNext(nextStep, values.email);
      }
    } catch (error) {
      onErrorValidate(error);
    }
  };


  return (<Form
    layout="vertical"
    name="validateForm"
    form={form}
    onFinish={onValidate}>
    <Form.Item
      label="Email"
      name="email"
      rules={[
        {
          required: true,
          message: 'Please enter your email',
        },
        {
          type: 'email',
          message: 'Please enter a valid email!'
        }
      ]} >
      <Input type="email" />
    </Form.Item>
    {isBlocked && <span className='font-weight-semibold ant-form-item-explain-error'>Kindly register with your business email.
      For freelancers, please fill this form <a href="https://share-eu1.hsforms.com/16ajIMlggQIWGaTaHczEebQf50ta">here</a> to create account.
    </span>}
    {isSpamBlocked && <span className='font-weight-semibold ant-form-item-explain-error'>We restrict signups from <a href={emailID}>{emailID}</a> for security reasons.
    </span>}
    <Form.Item
      name="recaptcha"
      rules={[
        {
          required: true,
          message: 'Please verify recaptcha',
        }
      ]}>
      <ReCaptchaV2 sitekey="6Lf4K-MUAAAAAKIuYTb0D83z99biKxuYXFnltIR5" ref={captcha}
        onChange={(token: string | null) => form.setFieldsValue({ recaptcha: token })} onExpired={() => form.setFieldsValue({ recaptcha: '' })} />

    </Form.Item>
    <p className='text-center'>By signing up, I agree to the&nbsp;<a href="https://www.rubitcube.com/" target='blank'>Privacy Policy</a> and&nbsp;
      <a href="https://www.rubitcube.com/" target='blank'>Terms of Service</a>&nbsp;ofRubitcube</p>
    <Form.Item>
      <div className="text-right mt-4">
        <Button type="primary" htmlType="submit" loading={loading}>
          Next
        </Button>
      </div>
    </Form.Item>
  </Form>);
};

export default ValidateEmailForm;
