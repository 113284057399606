import React from 'react';

export const WelcomeSideBanner = () =>
  // const backgroundStyle = {
  //   backgroundImage: 'url(/img/others/img-17.jpg)',
  //   backgroundRepeat: 'no-repeat',
  //   backgroundSize: 'cover'
  // };

   (<div>
     <div style={{position: "absolute", left:5, top:25}} className="px-4">
      <img src="/img/Logo-01_Black.png" alt="logo" />
       <img src="/img/plat01.png" alt="logo" />
     </div>
    <div style={{position: "absolute", left:0, bottom:0}} className="px-4">
      <img src="/img/others/img-17.jpg" alt="logo" />
    </div>
  </div>)
;

export default WelcomeSideBanner;
