import {
  DashboardOutlined,
  BulbOutlined,
  ContactsOutlined,
  // GoldOutlined,
  // AppstoreOutlined,
  // CodeOutlined,
  KeyOutlined,
  // RightSquareOutlined,
  // BankOutlined,
  // DollarOutlined,
  // AccountBookOutlined,
  MessageOutlined,
  ApiOutlined,
  // SettingOutlined,
  BookOutlined,
  // ThunderboltOutlined,
  CarryOutOutlined,
  ScheduleOutlined,
  DownloadOutlined,
  // SlackOutlined,
  AppstoreAddOutlined,
  // ForkOutlined,
  // AppstoreOutlined
} from '@ant-design/icons';
// import { ViberIconOutlined } from 'assets/svg/icon';

const appsNavTree = [{
  key: '/apps',
  path: `app`,
  title: 'sidenav.menu.empty',
  pageTitle: 'page.menu.empty',
  icon: BulbOutlined,
  breadcrumb: false,
  displayTitle: false,
  submenu: [{
    key: '/dashboard',
    path: `dashboard`,
    title: 'sidenav.dashboard',
    pageTitle: 'page.dashboard',
    icon: DashboardOutlined,
    breadcrumb: false,
    displayTitle: false,
    submenu: [
    ]
  },
  {
    key: '/contacts/all-contacts',
    path: `contacts/all-contacts`,
    title: 'sidenav.contacts',
    pageTitle: 'page.contacts',
    icon: ContactsOutlined,
    breadcrumb: false,
    displayTitle: false,
    permissionKey: 'CONTACTS',
    submenu: []
  },
  // {
  //   key: '/apps-developer',
  //   path: `developer-home`,
  //   title: 'sidenav.developer',
  //   pageTitle: 'page.developer',
  //   icon: CodeOutlined,
  //   breadcrumb: false,
  //   displayTitle: false,
  //   permissionKey: 'DEVELOPER',
  //   submenu: [
  //     {
  //       key: '/developer/applications',
  //       path: `developer/applications`,
  //       title: 'sidenav.developer.applications',
  //       pageTitle: 'page.developer.applications',
  //       icon: CloudOutlined,
  //       breadcrumb: false,
  //       displayTitle: true,
  //       permissionKey: 'DEVELOPER',
  //       submenu: []
  //     },
  //     {
  //       key: '/developer/sandbox',
  //       path: `developer/sandbox`,
  //       title: 'sidenav.developer.sandbox',
  //       pageTitle: 'page.developer.sandbox',
  //       icon: RightSquareOutlined,
  //       breadcrumb: false,
  //       displayTitle: true,
  //       permissionKey: 'DEVELOPER',
  //       submenu: []
  //     }
  //   ]
  // },
  {
    key: '/api-tokens',
    path: `api-tokens`,
    title: 'sidenav.applications',
    pageTitle: 'page.developer.applications',
    icon: KeyOutlined,
    breadcrumb: false,
    displayTitle: true,
    permissionKey: 'DEVELOPER',
    submenu: []
  },
  // {
  //   key: '/apps-billing',
  //   path: `billing-home`,
  //   title: 'sidenav.billing',
  //   pageTitle: 'page.billing',
  //   icon: DollarOutlined,
  //   breadcrumb: false,
  //   displayTitle: false,
  //   permissionKey: 'BILLING',
  //   submenu: [
  //     {
  //       key: '/billing/payment',
  //       path: `billing/payment`,
  //       title: 'sidenav.billing.payment',
  //       pageTitle: 'page.billing.payment',
  //       icon: BankOutlined,
  //       breadcrumb: false,
  //       displayTitle: false,
  //       permissionKey: 'BILLING_PAYMENT',
  //       submenu: []
  //     },
  //     {
  //       key: '/billing/invoice',
  //       path: `billing/invoice`,
  //       title: 'sidenav.billing.invoice',
  //       pageTitle: 'page.billing.invoice',
  //       icon: AccountBookOutlined,
  //       breadcrumb: false,
  //       displayTitle: false,
  //       permissionKey: 'BILLING_INVOICE',
  //       submenu: []
  //     },
  //     {
  //       key: '/billing/auto-recharge',
  //       path: `billing/auto-recharge`,
  //       title: 'sidenav.billing.autoRecharge',
  //       pageTitle: 'page.billing.autoRecharge',
  //       icon: ThunderboltOutlined,
  //       breadcrumb: false,
  //       displayTitle: false,
  //       permissionKey: 'BILLING_AUTO_RECHARGE',
  //       submenu: []
  //     }
  //   ]
  // },
  {
    key: '/apps-sms',
    path: `sms-home`,
    title: 'sidenav.sms',
    pageTitle: 'page.sms',
    icon: MessageOutlined,
    breadcrumb: false,
    displayTitle: false,
    permissionKey: 'SMS',
    submenu: [
      // {
      //   key: '/sms/getting-started',
      //   path: `sms/getting-started`,
      //   title: 'sidenav.sms.gettingStarted',
      //   pageTitle: 'page.sms.gettingStarted',
      //   icon: BulbOutlined,
      //   breadcrumb: false,
      //   displayTitle: false,
      //   permissionKey: 'DEVELOPER',
      //   submenu: []
      // },
      {
        key: '/sms/campaign',
        path: `sms/campaign`,
        title: 'sidenav.sms.campaign',
        pageTitle: 'page.sms.campaign',
        icon: CarryOutOutlined,
        breadcrumb: false,
        displayTitle: false,
        permissionKey: 'SMS_CAMPAIGN',
        submenu: []
      },
      {
        key: '/sms/campaign/manage-campaign',
        path: `sms/campaign/manage-campaign`,
        title: 'sidenav.sms.manageCampaign',
        pageTitle: 'page.sms.manageCampaign',
        icon: ScheduleOutlined,
        breadcrumb: false,
        displayTitle: false,
        permissionKey: 'SMS_CAMPAIGN',
        submenu: []
      },
      {
        key: '/sms/template',
        path: `sms/template`,
        title: 'sidenav.sms.template',
        pageTitle: 'page.sms.template',
        icon: AppstoreAddOutlined,
        breadcrumb: false,
        displayTitle: true,
        // permissionKey: 'SMS_TEMPLATE',
        submenu: []
      },
      {
        key: '/sms/campaign/campaign-report',
        path: `sms/campaign/campaign-report`,
        title: 'sidenav.sms.campaignReport',
        pageTitle: 'page.sms.campaignReport',
        icon: BookOutlined,
        breadcrumb: false,
        displayTitle: false,
        permissionKey: 'SMS_CAMPAIGN',
        submenu: []
      },
      {
        key: '/sms/api-report',
        path: `sms/api-report`,
        title: 'sidenav.sms.apiReport',
        pageTitle: 'page.sms.apiReport',
        icon: ApiOutlined,
        breadcrumb: false,
        displayTitle: true,
        permissionKey: 'SMS_REPORT',
        submenu: []
      },
      // {
      //   key: '/sms/scheduled-messages',
      //   path: `sms/scheduled-messages`,
      //   title: 'sidenav.sms.ScheduledMsgReport',
      //   pageTitle: 'page.sms.ScheduledMsgReport',
      //   icon: BookOutlined,
      //   breadcrumb: false,
      //   displayTitle: true,
      //   permissionKey: 'SMS_REPORT',
      //   submenu: []
      // },
      // {
      //   key: '/sms/pricing',
      //   path: `sms/pricing`,
      //   title: 'sidenav.sms.pricing',
      //   pageTitle: 'page.sms.pricing',
      //   icon: DollarOutlined,
      //   breadcrumb: false,
      //   displayTitle: true,
      //   permissionKey: 'SMS_REPORT',
      //   submenu: []
      // },
      // {
      //   key: '/sms/api-logs',
      //   path: `sms/api-logs`,
      //   title: 'sidenav.sms.apiLogs',
      //   pageTitle: 'page.sms.apiLogs',
      //   icon: ClockCircleOutlined,
      //   breadcrumb: false,
      //   displayTitle: true,
      //   permissionKey: 'DEVELOPER',
      //   submenu: []
      // },
      // {
      //   key: '/sms/settings',
      //   path: `sms/settings`,
      //   title: 'sidenav.sms.settings',
      //   pageTitle: 'page.sms.settings',
      //   icon: SettingOutlined,
      //   breadcrumb: false,
      //   displayTitle: false,
      //   permissionKey: 'SMS_SETTINGS',
      //   submenu: []
      // },
    ]
  },
  //   {
  //   key: '/apps-verify',
  //   path: `verify-home`,
  //   title: 'sidenav.verify',
  //   pageTitle: 'page.verify',
  //   icon: CheckSquareOutlined,
  //   breadcrumb: false,
  //   displayTitle: false,
  //   permissionKey: 'SMS_VERIFY',
  //   submenu: [
  //     {
  //       key: '/verify/getting-started',
  //       path: `verify/getting-started`,
  //       title: 'sidenav.verify.gettingStarted',
  //       pageTitle: 'page.verify.gettingStarted',
  //       icon: BulbOutlined,
  //       breadcrumb: false,
  //       displayTitle: false,
  //       permissionKey: 'DEVELOPER',
  //       submenu: []
  //     },
  //     {
  //       key: '/verify/report',
  //       path: `verify/report`,
  //       title: 'sidenav.verify.report',
  //       pageTitle: 'page.verify.report',
  //       icon: BookOutlined,
  //       breadcrumb: false,
  //       displayTitle: true,
  //       permissionKey: 'VERIFY_REPORT',
  //       submenu: []
  //     },
  //     // {
  //     //   key: '/verify/api-logs',
  //     //   path: `verify/api-logs`,
  //     //   title: 'sidenav.verify.apiLogs',
  //     //   pageTitle: 'page.verify.apiLogs',
  //     //   icon: ClockCircleOutlined,
  //     //   breadcrumb: false,
  //     //   displayTitle: true,
  //     //   permissionKey: 'DEVELOPER',
  //     //   submenu: []
  //     // }
  //     {
  //       key: '/verify/settings',
  //       path: `verify/settings`,
  //       title: 'sidenav.verify.settings',
  //       pageTitle: 'page.verify.settings',
  //       icon: SettingOutlined,
  //       breadcrumb: false,
  //       displayTitle: false,
  //       permissionKey: 'VERIFY_SETTINGS',
  //       submenu: []
  //     },
  //   ]
  // }
  // ,{
  //     key: '/apps-whatsapp',
  //     path: `whatsapp-home`,
  //     title: 'sidenav.whatsapp',
  //     pageTitle: 'page.whatsapp',
  //     icon: WhatsAppOutlined,
  //     breadcrumb: false,
  //     displayTitle: false,
  //     // permissionKey: 'Whatsapp',
  //     submenu: [
  //       {
  //         key: '/whatsapp/getting-started',
  //         path: `whatsapp/getting-started`,
  //         title: 'sidenav.whatsapp.gettingStarted',
  //         pageTitle: 'page.whatsapp.gettingStarted',
  //         icon: BulbOutlined,
  //         breadcrumb: false,
  //         displayTitle: false,
  //         // permissionKey: 'DEVELOPER',
  //         submenu: []
  //       },{
  //         key: '/whatsapp/whatsapp-campaign',
  //         path: `whatsapp/whatsapp-campaign`,
  //         title: 'sidenav.whatsapp.whatsappCampaign',
  //         pageTitle: 'page.whatsapp.whatsappCampaign',
  //         icon: CarryOutOutlined,
  //         breadcrumb: false,
  //         displayTitle: false,
  //         // permissionKey: 'DEVELOPER',
  //         submenu: []
  //       },
  //       {
  //         key: '/whatsapp/whatsapp-campaign/manage-whatsapp-campaign',
  //         path: `whatsapp/whatsapp-campaign/manage-whatsapp-campaign`,
  //         title: 'sidenav.whatsapp.ManageWhatsappCampaign',
  //         pageTitle: 'page.whatsapp.ManageWhatsappCampaign',
  //         icon: ScheduleOutlined,
  //         breadcrumb: false,
  //         displayTitle: false,
  //         // permissionKey: 'DEVELOPER',
  //         submenu: []
  //       },
  //       {
  //         key: '/whatsapp/whatsapp-campaign/whatsapp-campaign-report',
  //         path: `whatsapp/whatsapp-campaign/whatsapp-campaign-report`,
  //         title: 'sidenav.whatsapp.WhatsappCampaignReport',
  //         pageTitle: 'page.whatsapp.WhatsappCampaignReport',
  //         icon: BookOutlined,
  //         breadcrumb: false,
  //         displayTitle: false,
  //         // permissionKey: 'DEVELOPER',
  //         submenu: []
  //       },
  //       {
  //         key: '/whatsapp/whatsapp-template',
  //         path: `whatsapp/whatsapp-template`,
  //         title: 'sidenav.whatsapp.whatsappTemplateDetails',
  //         pageTitle: 'page.whatsapp.whatsappTemplateDetails',
  //         icon: AppstoreAddOutlined,
  //         breadcrumb: false,
  //         displayTitle: false,
  //         // permissionKey: 'DEVELOPER',
  //         submenu: []
  //       },
  //       // {
  //       //   key: '/chatbot',
  //       //   path: `chatbot`,
  //       //   title: 'sidenav.Chatbot',
  //       //   pageTitle: 'page.Chatbot',
  //       //   icon: RobotOutlined,
  //       //   breadcrumb: false,
  //       //   displayTitle: false,
  //       //   // permissionKey: 'WHATSAPP_SETTINGS',
  //       //   submenu: []
  //       // },
  //       {
  //         key: '/whatsapp/api-report',
  //         path: `whatsapp/api-report`,
  //         title: 'sidenav.whatsapp.apiReport',
  //         pageTitle: 'page.whatsapp.apiReport',
  //         icon: ApiOutlined,
  //         breadcrumb: false,
  //         displayTitle: true,
  //         // permissionKey: 'WHATSAPP_REPORT',
  //         submenu: []
  //       },
  //       {
  //         key: '/whatsapp/pricing',
  //         path: `whatsapp/pricing`,
  //         title: 'sidenav.whatsapp.pricing',
  //         pageTitle: 'page.whatsapp.pricing',
  //         icon: DollarOutlined,
  //         breadcrumb: false,
  //         displayTitle: true,
  //         // permissionKey: 'WHATSAPP_REPORT',
  //         submenu: []
  //       },
  //       {
  //         key: '/whatsapp/settings',
  //         path: `whatsapp/settings`,
  //         title: 'sidenav.whatsapp.settings',
  //         pageTitle: 'page.whatsapp.settings',
  //         icon: SettingOutlined,
  //         breadcrumb: false,
  //         displayTitle: false,
  //         // permissionKey: 'WHATSAPP_SETTINGS',
  //         submenu: []
  //       },
  //       //  {
  //       //   key: '/whatsapp/whatsapp-conversation-flow',
  //       //   path: `whatsapp/whatsapp-conversation-flow`,
  //       //   title: 'sidenav.whatsapp.WhatsappConversationFlow',
  //       //   pageTitle: 'page.whatsapp.WhatsappConversationFlow',
  //       //   breadcrumb: false,
  //       //   displayTitle: false,
  //       //   // permissionKey: 'WHATSAPP_SETTINGS',
  //       //   submenu: []
  //       // },
  //     ]
  //   }
  //   ,{
  //     key: '/apps-whatsapp-meta',
  //     path: `apps-whatsapp-meta`,
  //     title: 'sidenav.whatsapp-meta',
  //     pageTitle: 'page.whatsapp-meta',
  //     icon: WhatsAppOutlined,
  //     breadcrumb: false,
  //     displayTitle: false,
  //     // permissionKey: 'Whatsapp',
  //     submenu: [
  //       // {
  //       //   key: '/whatsapp-meta/connect-whatsapp',
  //       //   path: `whatsapp-meta/connect-whatsapp`,
  //       //   title: 'sidenav.whatsapp-meta.connectWhatsapp',
  //       //   pageTitle: 'page.whatsapp-meta.connectWhatsapp',
  //       //   icon: ForkOutlined,
  //       //   breadcrumb: false,
  //       //   displayTitle: false,
  //       //   // permissionKey: 'DEVELOPER',
  //       //   submenu: []
  //       // },
  //       {
  //         key: '/whatsapp-meta/getting-started',
  //         path: `whatsapp-meta/getting-started`,
  //         title: 'sidenav.whatsapp-meta.gettingStarted',
  //         pageTitle: 'page.whatsapp-meta.gettingStarted',
  //         icon: BulbOutlined,
  //         breadcrumb: false,
  //         displayTitle: false,
  //         // permissionKey: 'DEVELOPER',
  //         submenu: []
  //       },
  //       {
  //         key: '/whatsapp-meta/registered-numbers',
  //         path: `whatsapp-meta/registered-numbers`,
  //         title: 'sidenav.whatsapp-meta.numbers',
  //         pageTitle: 'page.whatsapp-meta.numbers',
  //         icon: SettingOutlined,
  //         breadcrumb: false,
  //         displayTitle: false,
  //         // permissionKey: 'WHATSAPP_SETTINGS',
  //         submenu: []
  //       },
  //       {
  //         key: '/whatsapp-meta/whatsapp-meta-template',
  //         path: `whatsapp-meta/whatsapp-meta-template`,
  //         title: 'sidenav.whatsapp-meta.whatsappTemplateDetails',
  //         pageTitle: 'page.whatsapp-meta.whatsappTemplateDetails',
  //         icon: AppstoreAddOutlined,
  //         breadcrumb: false,
  //         displayTitle: false,
  //         // permissionKey: 'DEVELOPER',
  //         submenu: []
  //       },
  //       {
  //         key: '/whatsapp-meta/whatsapp-campaign',
  //         path: `whatsapp-meta/whatsapp-campaign`,
  //         title: 'sidenav.whatsapp-meta.whatsappCampaign',
  //         pageTitle: 'page.whatsapp-meta.whatsappCampaign',
  //         icon: CarryOutOutlined,
  //         breadcrumb: false,
  //         displayTitle: false,
  //         // permissionKey: 'DEVELOPER',
  //         submenu: []
  //       },
  //       {
  //         key: '/whatsapp-meta/whatsapp-campaign/manage-whatsapp-campaign',
  //         path: `whatsapp-meta/whatsapp-campaign/manage-whatsapp-campaign`,
  //         title: 'sidenav.whatsapp-meta.ManageWhatsappCampaign',
  //         pageTitle: 'page.whatsapp-meta.ManageWhatsappCampaign',
  //         icon: ScheduleOutlined,
  //         breadcrumb: false,
  //         displayTitle: false,
  //         // permissionKey: 'DEVELOPER',
  //         submenu: []
  //       },
  //       {
  //         key: '/whatsapp-meta/whatsapp-campaign/whatsapp-campaign-report',
  //         path: `whatsapp-meta/whatsapp-campaign/whatsapp-campaign-report`,
  //         title: 'sidenav.whatsapp-meta.WhatsappCampaignReport',
  //         pageTitle: 'page.whatsapp-meta.WhatsappCampaignReport',
  //         icon: BookOutlined,
  //         breadcrumb: false,
  //         displayTitle: false,
  //         // permissionKey: 'DEVELOPER',
  //         submenu: []
  //       },
  //       {
  //         key: '/whatsapp-meta/meta-chatbot',
  //         path: `whatsapp-meta/meta-chatbot`,
  //         title: 'sidenav.whatsapp-meta.Chatbot',
  //         pageTitle: 'page.whatsapp-meta.Chatbot',
  //         icon: RobotOutlined,
  //         breadcrumb: false,
  //         displayTitle: false,
  //         // permissionKey: 'WHATSAPP_SETTINGS',
  //         submenu: []
  //       },
  //       {
  //         key: '/whatsapp-meta/api-report',
  //         path: `whatsapp-meta/api-report`,
  //         title: 'sidenav.whatsapp-meta.apiReport',
  //         pageTitle: 'page.whatsapp-meta.apiReport',
  //         icon: ApiOutlined,
  //         breadcrumb: false,
  //         displayTitle: true,
  //         // permissionKey: 'WHATSAPP_REPORT',
  //         submenu: []
  //       },
  //       {
  //         key: '/whatsapp-meta/settings',
  //         path: `whatsapp-meta/settings`,
  //         title: 'sidenav.whatsapp-meta.settings',
  //         pageTitle: 'page.whatsapp-meta.settings',
  //         icon: SettingOutlined,
  //         breadcrumb: false,
  //         displayTitle: false,
  //         // permissionKey: 'WHATSAPP_SETTINGS',
  //         submenu: []
  //       }
  //     ]
  //   },
  //    // {
  //    //      key: '/chatbot',
  //    //      path: `chatbot`,
  //    //      title: 'sidenav.Chatbot',
  //    //      pageTitle: 'page.Chatbot',
  //    //      icon: RobotOutlined,
  //    //      breadcrumb: false,
  //    //      displayTitle: false,
  //    //      // permissionKey: 'WHATSAPP_SETTINGS',
  //    //      submenu: []
  //    //    },
  //   {
  //     key: '/apps-numlookup',
  //     path: `numlookup-home`,
  //     title: 'sidenav.numlookup',
  //     pageTitle: 'page.numlookup',
  //     icon: SearchOutlined,
  //     breadcrumb: false,
  //     displayTitle: false,
  //     permissionKey: 'Viber',
  //     submenu: [
  //       {
  //         key: '/numlookup/getting-started',
  //         path: `numlookup/getting-started`,
  //         title: 'sidenav.numlookup.gettingStarted',
  //         pageTitle: 'page.numlookup.gettingStarted',
  //         icon: BulbOutlined,
  //         breadcrumb: false,
  //         displayTitle: false,
  //         permissionKey: 'DEVELOPER',
  //         submenu: []
  //       },
  //       {
  //         key: '/numlookup/api-report',
  //         path: `numlookup/api-report`,
  //         title: 'sidenav.numlookup.apiReport',
  //         pageTitle: 'page.numlookup.apiReport',
  //         icon: ApiOutlined,
  //         breadcrumb: false,
  //         displayTitle: true,
  //         permissionKey: 'NUMLOOKUP_REPORT',
  //         submenu: []
  //       },
  //       {
  //         key: '/numlookup/pricing',
  //         path: `numlookup/pricing`,
  //         title: 'sidenav.numlookup.pricing',
  //         pageTitle: 'page.numlookup.pricing',
  //         icon: DollarOutlined,
  //         breadcrumb: false,
  //         displayTitle: true,
  //         permissionKey: 'NUMLOOKUP_REPORT',
  //         submenu: []
  //       },
  //     ]
  //   },{
  //     key: '/apps-viber',
  //     path: `viber-home`,
  //     title: 'sidenav.viber',
  //     pageTitle: 'page.viber',
  //     icon: ViberIconOutlined,
  //     breadcrumb: false,
  //     displayTitle: false,
  //     permissionKey: 'Viber',
  //     submenu: [
  //       {
  //         key: '/viber/getting-started',
  //         path: `viber/getting-started`,
  //         title: 'sidenav.viber.gettingStarted',
  //         pageTitle: 'page.viber.gettingStarted',
  //         icon: BulbOutlined,
  //         breadcrumb: false,
  //         displayTitle: false,
  //         permissionKey: 'DEVELOPER',
  //         submenu: []
  //       },
  //       {
  //         key: '/viber/api-report',
  //         path: `viber/api-report`,
  //         title: 'sidenav.viber.apiReport',
  //         pageTitle: 'page.viber.apiReport',
  //         icon: ApiOutlined,
  //         breadcrumb: false,
  //         displayTitle: true,
  //         permissionKey: 'VIBER_REPORT',
  //         submenu: []
  //       },
  //       {
  //         key: '/viber/pricing',
  //         path: `viber/pricing`,
  //         title: 'sidenav.viber.pricing',
  //         pageTitle: 'page.viber.pricing',
  //         icon: DollarOutlined,
  //         breadcrumb: false,
  //         displayTitle: true,
  //         permissionKey: 'VIBER_REPORT',
  //         submenu: []
  //       },
  //       {
  //         key: '/viber/settings',
  //         path: `viber/settings`,
  //         title: 'sidenav.viber.settings',
  //         pageTitle: 'page.viber.settings',
  //         icon: SettingOutlined,
  //         breadcrumb: false,
  //         displayTitle: false,
  //         permissionKey: 'VIBER_CONFIG',
  //         submenu: []
  //       },
  //     ]
  //   },
  //   {
  //     key: '/integrations',
  //     path: `integrations`,
  //     title: 'sidenav.integrations',
  //     pageTitle: 'page.integrations',
  //     icon: AppstoreOutlined,
  //     breadcrumb: false,
  //     displayTitle: true,
  //     permissionKey: 'DEVELOPER',
  //     submenu: [
  //     ]
  //   },
    // {
    //   key: '/apps-slack',
    //   path: `slack-home`,
    //   title: 'sidenav.slack',
    //   pageTitle: 'page.slack',
    //   icon: SlackOutlined,
    //   breadcrumb: false,
    //   displayTitle: false,
    //   permissionKey: 'Slack',
    //   submenu: [
    //     {
    //       key: '/slack/getting-started',
    //       path: `slack/getting-started`,
    //       title: 'sidenav.slack.gettingStarted',
    //       pageTitle: 'page.slack.gettingStarted',
    //       icon: BulbOutlined,
    //       breadcrumb: false,
    //       displayTitle: false,
    //       permissionKey: 'DEVELOPER',
    //       submenu: []
    //     },
    //     {
    //       key: '/slack/workspace-details',
    //       path: `slack/workspace-details`,
    //       title: 'sidenav.slack.workspaceDetails',
    //       pageTitle: 'page.slack.workspaceDetails',
    //       icon: AppstoreAddOutlined,
    //       breadcrumb: false,
    //       displayTitle: false,
    //       permissionKey: 'DEVELOPER',
    //       submenu: []
    //     },
    //     {
    //       key: '/slack/api-report',
    //       path: `slack/api-report`,
    //       title: 'sidenav.slack.apiReport',
    //       pageTitle: 'page.slack.apiReport',
    //       icon: ApiOutlined,
    //       breadcrumb: false,
    //       displayTitle: true,
    //       permissionKey: 'SLACK_REPORT',
    //       submenu: []
    //     },
    //     {
    //       key: '/slack/settings',
    //       path: `slack/settings`,
    //       title: 'sidenav.slack.settings',
    //       pageTitle: 'page.slack.settings',
    //       icon: SettingOutlined,
    //       breadcrumb: false,
    //       displayTitle: false,
    //       permissionKey: 'SLACK_SETTINGS',
    //       submenu: []
    //     },
    //   ]
    // },
  {
    key: '/importexport',
    path: `importexport`,
    title: 'sidenav.importexport',
    pageTitle: 'page.importexport',
    icon: DownloadOutlined,
    breadcrumb: false,
    displayTitle: true,
    permissionKey: 'IMPORTEXPORT',
    submenu: [
    ]
  },
  ]
}
];

const navigationConfig = [
  ...appsNavTree,
];

export default navigationConfig;
