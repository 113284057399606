import React, { Suspense, lazy } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from 'antd';
import useBodyClass from 'hooks/useBodyClass';
import AuthPageLayout from "layouts/auth";
import AppPageLayout from "layouts/app";
import { RootState } from "App";
import Loading from "components/shared/Loading";
import ErrorPages from "./Error/ErrorPages";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
// import DashboardPages from "./Dashboard/DashboardPages";
import BillingPages from "./Billing/BillingPages";
import ContactPages from "./Contact/ContactPages";
import DeveloperPages from "./Developer/DeveloperPages";
// import IntegrationPages from "./Integrations/IntegrationPages";
import SMSPages from "./SMS/SMSPages";
// import ViberPages from "./Viber/ViberPages";
// import VerifyPages from "./Verify/VerifyPages";
import UserPages from "./User/UserPages";
import ProtectedRoute from "./ProtectedRoute";
import ForgotPasswordPage from "./ForgotPassword";
import SetPasswordPage from "./SetPassword";
import VerfiyEmailPage from "./SignUp/VerifyEmailLandingPage";
import ImportExportPages from "./ImportExport/ImportExportPages";
// import SlackPages from "./Slack/SlackPages";
// import NumLookupPages from "./NumLookup/NumLookupPages";
// import WhatsappPages from "./Whatsapp/WhatsappPages";
// import Chatbot from "./Chatbot";
// import ChatbotPages from "./Chatbot/ChatbotPages";
// import WhatsappMetaPages from "./WhatsappMeta/WhatsappMetaPages";

const DashboardPages = lazy(() => import("./Dashboard/DashboardPages"));

export const Pages = () => {
  const { token } = useSelector((state: RootState) => state.auth);
  const { direction, locale } = useSelector((state: RootState) => state.theme);
  const currentAppLocale = AppLocale[locale];
  // const currentAppLocale = AppLocale.en;
  const location = useLocation();

  useBodyClass(`dir-${direction}`);
  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Routes>
          <Route element={<AuthPageLayout />}>
            <Route path='signin' element={<SignIn />}>
              <Route path=':from' element={<SignIn />} />
            </Route>
            <Route path='/signup' element={<SignUp />} />
            <Route path='forgot-password' element={<ForgotPasswordPage />}>
              <Route path=':token' element={<ForgotPasswordPage />} />
            </Route>
            <Route path='/set-password/:token' element={<SetPasswordPage />} />
            <Route path='/signup/verify-email' element={<VerfiyEmailPage />} />
          </Route>
          <Route element={<AppPageLayout />}>
            <Route path='/*' element={<Suspense fallback={<Loading cover="content" />}>
              <ProtectedRoute
                token={token}
                redirectTo="/signin"
                location={location}
              >
                <Routes>
                  <Route path="dashboard/*" element={<DashboardPages />} />
                  <Route path="billing/*" element={<BillingPages />} />
                  <Route path="contacts/*" element={<ContactPages />} />
                  {/* <Route path="integrations/*" element={<IntegrationPages />} /> */}
                  <Route path='api-tokens/*' element={<DeveloperPages />} />
                  <Route path="sms/*" element={<SMSPages />} />
                  {/* <Route path="slack/*" element={<SlackPages />} /> */}
                  {/* <Route path="verify/*" element={<VerifyPages />} /> */}
                  {/* <Route path="viber/*" element={<ViberPages />} /> */}
                  {/* <Route path="numlookup/*" element={<NumLookupPages />} /> */}
                  {/* <Route path="whatsapp/*" element={<WhatsappPages />} /> */}
                  {/* <Route path="whatsapp-meta/*" element={<WhatsappMetaPages />} /> */}
                  {/* <Route path="chatbot/*" element={<ChatbotPages />} /> */}
                  <Route path="users/*" element={<UserPages />} />
                  <Route path="importexport/*" element={<ImportExportPages />} />
                  <Route path="*" element={<Navigate to="/error/404" replace />} />
                  {/* <Route path="forgot-password/*" element={<ForgotPasswordPages />}/> */}
                </Routes>
              </ProtectedRoute></Suspense>} />

          </Route>
          <Route path='/error/*' element={<ErrorPages />} />
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
          <Route path="*" element={<Navigate to="/error/404" replace />} />
        </Routes>

      </ConfigProvider>
    </IntlProvider>
  );
};

export default React.memo(Pages);
