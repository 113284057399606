import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from "antd";
import LoginForm from 'components/signIn/LoginForm';
import { RootState } from 'App';
import WelcomeSideBanner from 'components/layout/WelcomeSideBanner';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';


export const SignIn = () => {
  const theme = useSelector((state: RootState) => state.theme.currentTheme);

  const { from } = useParams();



  return (
    <div className={`h-100 ${theme === 'light' ? 'bg-white' : ''}`}>
      <Helmet>
        <title>Securely Login to Your Account | Rubitcube</title>
      </Helmet>
      <Row justify="center" className="align-items-stretch h-100">
        <Col xs={0} sm={0} md={0} lg={8}>
          <WelcomeSideBanner />
        </Col>
        <Col xs={20} sm={20} md={24} lg={16}>
          <div className="container d-flex flex-column justify-content-center h-100">
            <Row justify="center">
              <Col xs={24} sm={24} md={20} lg={12} xl={8}>
                <h2 style={{fontSize:'30px'}}>Sign In</h2>
                 {/* <p>Don&apos; t have an account yet? <a href="/signup">Sign Up</a></p> */}
                <div className="mt-4 text-center">
                  <LoginForm showForgetPassword from={from} />
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SignIn;
