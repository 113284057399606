const config = {
  API_ENDPOINT_URL: process.env.REACT_APP_API_BASE_URL,
  SLACK_APP_ENDPOINT_URL: process.env.REACT_APP_SLACK_APP_BASE_URL,
  ZOHO_APP_ENDPOINT_URL: process.env.REACT_APP_ZOHO_APP_BASE_URL,
  HUBSPOT_APP_ENDPOINT_URL: process.env.REACT_APP_HUBSPOT_APP_BASE_URL,
  WHATSAPP_META_APP_ID: process.env.REACT_APP_WHATSAPP_APP_ID,
  WHATSAPP_META_CONFIG_ID: process.env.REACT_APP_WHATSAPP_CONFIG_ID,
  GITHUB_TOKEN: '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',
  GITHUB_CONTENT_PATH: 'https://api.github.com/repos/d7networks/getting_started_codes/contents/',
  INTEGRATION_CONTENT_PATH: 'https://d7networks.com/webapi/v2/pages/12/?fields=integration_cards',
  INTEGRATION_TOKEN: '0c21fe0a2e20f5159323373933c2e79fc5a14a40'
};
const getEnv = () => config;

export default getEnv();
