import {
  WhatsappTemplateDeleteRequest,
  WhatsappSettingsUpdateRequest,
  WhatsappApiReportFilterRequest,
  WhatsappApiReportExportRequest,
  WhatsappOriginatorRegisterRequest,
  WhatsappTemplateCreateRequest,
  WhatsappTemplateUpdateRequest,
  WhatsappMetaOnboardRequest
} from "../../models/requests";
import {
  getWhatsappSettings,
  updateWhatsappSettings,
  listWhatsappTemplate,
  deleteWhatsappTemplate,
  sendWhatsappOriginatorRegisterRequest,
  createWhatsappTemplate,
  sendWhatsappShowInterestRequest,
  getOnboardingDetails,
  listWhatsappContact, listWhatsappTemplateGettingStarted, updateWhatsappTemplate,
  getWhatsappPricing, getWhatsappTemplate, getWhatsappSubscription, listMarketingWhatsappTemplate, saveWhatsappToken
} from "../../api/whatsapp/WhatsappApi";

import {
  createWhatsappMetaTemplate,
  deleteWhatsappMetaTemplate,
  listWhatsappMetaRegisteredNumbers,
  listWhatsappMetaWabaId,
  uploadResumableFile,
  whatsappMetaOnboarding,
  listWhatsappMetaTemplate,
  updateWhatsappMetaTemplate,
  listWhatsappMetaMarketingTemplate,
  listWhatsappMetaAuthenticationTemplate,
  getOriginator,
  uploadMediaFile,
  getWhatsappMetaRegisteredNumbers,
  getWhatsappMetaBusinessProfile, updateWhatsappMetaBusinessProfile
} from "../../api/whatsapp_meta/WhatsappMetaApi";

import {
  getWhatsappApiFilterResult,
  getWhatsappApiReportExport,
  getWhatsappApiSearchResult
} from "../../api/whatsapp/WhatsappReportApi";
import {WhatsappMetaBusinessProfile} from "../../models";

const getWhatsappSettingsService = async () => getWhatsappSettings();
const updateWhatsappSettingsService = async (data: WhatsappSettingsUpdateRequest) => updateWhatsappSettings(data);

const createWhatsappTemplateService = async (data: WhatsappTemplateCreateRequest) => createWhatsappTemplate(data);
const updateWhatsappTemplateService = async (data: WhatsappTemplateUpdateRequest, templateId: number) => updateWhatsappTemplate(data, templateId);
const listWhatsappTemplateService = async () => listWhatsappTemplate();
const listMarketingWhatsappTemplateService = async () => listMarketingWhatsappTemplate();
const listWhatsappTemplateGettingStartedService = async () => listWhatsappTemplateGettingStarted();
const getWhatsappTemplateService = async (templateName: string) => getWhatsappTemplate(templateName);
const deleteWhatsappTemplateService = async (data: WhatsappTemplateDeleteRequest) => deleteWhatsappTemplate(data);

const getWhatsappApiSearchResultService = async (key: string, data: WhatsappApiReportFilterRequest) => getWhatsappApiSearchResult(key, data);
const getWhatsappApiFilterResultService = async (data: WhatsappApiReportFilterRequest) => getWhatsappApiFilterResult(data);
const getWhatsappApiReportExportService = async (data: WhatsappApiReportExportRequest) => getWhatsappApiReportExport(data);

const registerWhatsappOriginatorService = async (data: WhatsappOriginatorRegisterRequest) => sendWhatsappOriginatorRegisterRequest(data);
const whatsappShowInterestService = async () => sendWhatsappShowInterestRequest();
const getWhatsappOnboardingService = async () => getOnboardingDetails();

const listWhatsappContactService = async () => listWhatsappContact();
const getWhatsappPricingService = async () => getWhatsappPricing();
const getWhatsappSubscriptionService = async () => getWhatsappSubscription();

const saveWhatsappTokenService = async (data: { "token": string }) => saveWhatsappToken(data);

const onboardWhatsappBusinessAccountService = async (data: WhatsappMetaOnboardRequest) => whatsappMetaOnboarding(data);

const listWhatsappMetaRegisteredNumbersService = async () => listWhatsappMetaRegisteredNumbers();
const getWhatsappMetaRegisteredNumbersService = async (numberId: number) => getWhatsappMetaRegisteredNumbers(numberId);

const listWhatsappMetaTemplateService = async (wabaId: string) => listWhatsappMetaTemplate(wabaId);

const listWhatsappMetaMarketingTemplateService = async (number: string) => listWhatsappMetaMarketingTemplate(number);
const listWhatsappMetaAuthenticationTemplateService = async (number: string) => listWhatsappMetaAuthenticationTemplate(number);

const createWhatsappMetaTemplateService = async (data: WhatsappTemplateCreateRequest) => createWhatsappMetaTemplate(data);

const updateWhatsappMetaTemplateService = async (data: WhatsappTemplateUpdateRequest, templateId: number) => updateWhatsappMetaTemplate(data, templateId);

const deleteWhatsappMetaTemplateService = async (data: WhatsappTemplateDeleteRequest) => deleteWhatsappMetaTemplate(data);

const listWhatsappMetaWabaIdService = async () => listWhatsappMetaWabaId();

const uploadResumableFileService = async (file: any) => {
  const data = new FormData();
  data.append('file', file);
  return uploadResumableFile(data);
};

const uploadMediaFileService = async (originator: any, file: any) => {
  const data = new FormData();
  data.append('file', file);
  return uploadMediaFile(originator, data);
};

const getOriginatorDetailsService = async (originator?:string) => getOriginator(originator);

const getWhatsappMetaBusinessProfileService = async (numberId: number) => getWhatsappMetaBusinessProfile(numberId);

const updateWhatsappMetaBusinessProfileService = async (ContactNumber: string | undefined, data: WhatsappMetaBusinessProfile) => updateWhatsappMetaBusinessProfile(ContactNumber, data);

export {
  getWhatsappSettingsService,
  updateWhatsappSettingsService,
  createWhatsappTemplateService,
  updateWhatsappTemplateService,
  listWhatsappTemplateService,
  listMarketingWhatsappTemplateService,
  deleteWhatsappTemplateService,
  getWhatsappApiSearchResultService,
  getWhatsappApiFilterResultService,
  getWhatsappApiReportExportService,
  registerWhatsappOriginatorService,
  whatsappShowInterestService,
  getWhatsappOnboardingService,
  listWhatsappContactService,
  listWhatsappTemplateGettingStartedService,
  getWhatsappPricingService,
  getWhatsappTemplateService,
  getWhatsappSubscriptionService,
  saveWhatsappTokenService,
  onboardWhatsappBusinessAccountService,
  listWhatsappMetaRegisteredNumbersService,
  createWhatsappMetaTemplateService,
  deleteWhatsappMetaTemplateService,
  uploadResumableFileService,
  listWhatsappMetaWabaIdService,
  listWhatsappMetaTemplateService,
  updateWhatsappMetaTemplateService,
  listWhatsappMetaMarketingTemplateService,
  listWhatsappMetaAuthenticationTemplateService,
  getOriginatorDetailsService,
  uploadMediaFileService,
  getWhatsappMetaRegisteredNumbersService,
  getWhatsappMetaBusinessProfileService,
  updateWhatsappMetaBusinessProfileService
};
