import React from "react";
import { RootState } from "App";
import { AuthState } from "models";
import { useSelector } from "react-redux";
import { Navigate, Location } from "react-router-dom";
import Loading from "components/shared/Loading";
// import Loading from "components/shared/Loading";

const ProtectedRoute = (props: {
  redirectTo: string,
  location: Location,
  token: any,
  children: JSX.Element
}): JSX.Element => {
  // const dispatch = useDispatch();
  const {
    acl, permissionMaps
  } = useSelector((state: RootState): AuthState => state.auth);
  const {
    redirectTo,
    location,
    token,
    children
  } = props;
  const checkPermission = (path: string) => {
    let newPath = path;
    if (path.startsWith('/sms/campaign/')) {
      newPath = '/sms/campaign/:id';
    }
    if (path.startsWith('/whatsapp-meta/whatsapp-campaign/')) {
      newPath = '/whatsapp-meta/whatsapp-campaign/:id';
    }
    if (path.startsWith('/whatsapp/whatsapp-campaign/')) {
      newPath = '/whatsapp/whatsapp-campaign/:id';
    }
    if (path.startsWith('/chatbot/')) {
      newPath = '/chatbot/:name/:id';
    }
    if (path.startsWith('/whatsapp-meta/meta-chatbot/')) {
      newPath = '/whatsapp-meta/meta-chatbot/:name/:id';
    }
    if (path.startsWith('/contacts/all-contacts/')) {
      newPath = '/contacts/all-contacts/:groupId';
    }
    if (path.startsWith('/whatsapp-meta/registered-numbers/')) {
      newPath = '/whatsapp-meta/registered-numbers/:id';
    }

    if (permissionMaps.findIndex(pm => pm.path === newPath) >= 0) {
      return true;
    }
    return false;
  };

  if (token) {
    if (acl) {
      return checkPermission(location.pathname) ? children : <Navigate
        to={{
          pathname: '/error/403'
        }}
        state={{ from: location }}
      />;
    }
    return <Loading cover="page" />;
  }

  return <Navigate
    to={{
      pathname: `${redirectTo}${ location && location.pathname && !location.pathname.includes('signin') ? location.pathname : '' }`
    }}
    state={{ from: location }}
  />;
};

export default ProtectedRoute;
