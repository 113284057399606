import { PermissionMap } from "models";
import { AuthListResponse } from 'models/responses';

const permissionsMap = [{
  key: 'dashboard',
  path: `/dashboard`,
  permissionKeys: []
},
  {
    key: 'apps-contacts',
    path: `/apps-contacts`,
    permissionKeys: ['CONTACTS']
  },
  {
    key: 'contacts/all-contacts',
    path: `/contacts/all-contacts`,
    permissionKeys: ['CONTACTS']
  },
  {
    key: 'contacts/all-contacts/groups',
    path: `/contacts/all-contacts/groups`,
    permissionKeys: ['CONTACTS']
  },{
    key: 'contacts/all-contacts/crm-redirect',
    path: `/contacts/all-contacts/crm-redirect`,
    permissionKeys: ['CONTACTS']
  },{
    key: 'integrations',
    path: `/integrations`,
    permissionKeys: ['DEVELOPER']
  },{
    key: 'integrations',
    path: `/integrations/crm-redirect`,
    permissionKeys: ['DEVELOPER']
  },{
    key: 'integrations',
    path: `/integrations/hubspot`,
    permissionKeys: ['DEVELOPER']
  },{
    key: 'integrations',
    path: `/integrations/zoho`,
    permissionKeys: ['DEVELOPER']
  },
  {
    key: 'apps-developer',
    path: `/apps-developer`,
    permissionKeys: ['DEVELOPER']
  },
  {
    key: 'api-tokens',
    path: `/api-tokens`,
    permissionKeys: ['DEVELOPER']
  },
  {
    key: 'developer/sandbox',
    path: `/developer/sandbox`,
    permissionKeys: ['DEVELOPER']
  },
  {
    key: 'apps-billing',
    path: `/apps-billing`,
    permissionKeys: ['BILLING_PAYMENT', 'BILLING_INVOICE', 'BILLING_AUTO_RECHARGE']
  },
  {
    key: 'billing/payment',
    path: `/billing/payment`,
    permissionKeys: ['BILLING_PAYMENT']
  }, {
    key: 'billing/invoice',
    path: `/billing/invoice`,
    permissionKeys: ['BILLING_INVOICE']
  }, {
    key: 'billing/auto-recharge',
    path: `/billing/auto-recharge`,
    permissionKeys: ['BILLING_AUTO_RECHARGE']
  }, {
    key: 'apps-sms',
    path: `/apps-sms`,
    permissionKeys: ['SMS_CAMPAIGN', 'SMS_SETTINGS', 'DEVELOPER', 'SMS_TEMPLATE']
  }, {
    key: 'sms/getting-started',
    path: `/sms/getting-started`,
    permissionKeys: ['DEVELOPER']
  },
  {
    key: 'sms/campaign',
    path: `/sms/campaign`,
    permissionKeys: ['SMS_CAMPAIGN']
  }, {
    key: 'sms/settings',
    path: `/sms/settings`,
    permissionKeys: ['SMS_SETTINGS']
  }, {
    key: 'sms/manage-campaign',
    path: `sms/manage-campaign`,
    permissionKeys: ['SMS_CAMPAIGN']
  }, {
    key: 'sms/campaign-report',
    path: `/sms/campaign-report`,
    permissionKeys: ['SMS_CAMPAIGN']
  }, {
    key: 'sms/api-report',
    path: `/sms/api-report`,
    permissionKeys: ['SMS_REPORT']
  },{
    key: 'sms/api-logs',
    path: `/sms/api-logs`,
    permissionKeys: ['DEVELOPER']
  },{
    key: 'sms/template',
    path: `/sms/template`,
    permissionKeys: ['SMS_TEMPLATE']
  },{
    key: 'sms/scheduled-messages',
    path: `/sms/scheduled-messages`,
    permissionKeys: ['SMS_REPORT']
  },{
    key: 'sms/pricing',
    path: `/sms/pricing`,
    permissionKeys: ['SMS_REPORT']
  },
  {
    key: 'apps-slack',
    path: `/apps-slack`,
    permissionKeys: ['SLACK_SETTINGS', 'DEVELOPER']
  }, {
    key: 'slack/getting-started',
    path: `/slack/getting-started`,
    permissionKeys: ['DEVELOPER']
  },
  {
    key: 'slack/workspace-details',
    path: `/slack/workspace-details`,
    permissionKeys: ['DEVELOPER']
  },
  {
    key: 'slack/slack-redirect',
    path: `/slack/slack-redirect`,
    permissionKeys: ['DEVELOPER']
  },
  {
    key: 'slack/settings',
    path: `/slack/settings`,
    permissionKeys: ['SLACK_SETTINGS']
  }, {
    key: 'slack/api-report',
    path: `/slack/api-report`,
    permissionKeys: ['SLACK_REPORT']
  },
  {
    key: 'apps-verify',
    path: `/apps-verify`,
    permissionKeys: ['VERIFY_REPORT', 'VERIFY_SETTINGS', 'DEVELOPER']
  }, {
    key: 'verify/getting-started',
    path: `/verify/getting-started`,
    permissionKeys: ['DEVELOPER']
  }, {
    key: 'verify/settings',
    path: `/verify/settings`,
    permissionKeys: ['VERIFY_SETTINGS']
  }, {
    key: 'verify/report',
    path: `/verify/report`,
    permissionKeys: ['VERIFY_REPORT']
  }, {
    key: 'verify/api-logs',
    path: `/verify/api-logs`,
    permissionKeys: ['DEVELOPER']
  },{
    key: 'apps-viber',
    path: `/apps-viber`,
    permissionKeys: [ 'VIBER_SETTINGS', 'DEVELOPER']
  }, {
    key: 'viber/getting-started',
    path: `/viber/getting-started`,
    permissionKeys: ['DEVELOPER']
  },
  {
    key: 'viber/settings',
    path: `/viber/settings`,
    permissionKeys: ['VIBER_SETTINGS']
  },{
    key: 'viber/api-report',
    path: `/viber/api-report`,
    permissionKeys: ['VIBER_REPORT']
  }, {
    key: 'viber/pricing',
    path: `/viber/pricing`,
    permissionKeys: ['VIBER_REPORT']
  },{
    key: 'numlookup/getting-started',
    path: `/numlookup/getting-started`,
    permissionKeys: ['DEVELOPER']
  },
  {
    key: 'numlookup/pricing',
    path: `/numlookup/pricing`,
    permissionKeys: ['NUMLOOKUP_REPORT']
  },{
    key: 'numlookup/api-report',
    path: `/numlookup/api-report`,
    permissionKeys: ['NUMLOOKUP_REPORT']
  },
  {
    key: 'apps-whatsapp',
    path: `/apps-whatsapp`,
    permissionKeys: ['WHATSAPP_SETTINGS', 'DEVELOPER', 'WHATSAPP_CAMPAIGN']
  }, {
    key: 'whatsapp/getting-started',
    path: `/whatsapp/getting-started`,
    permissionKeys: ['DEVELOPER']
  },
  {
    key: 'whatsapp/whatsapp-campaign',
    path: `/whatsapp/whatsapp-campaign`,
    permissionKeys: ['WHATSAPP_CAMPAIGN']
  },
  {
    key: 'whatsapp/manage-whatsapp-campaign',
    path: `/whatsapp/manage-whatsapp-campaign`,
    permissionKeys: ['WHATSAPP_CAMPAIGN']
  },
  {
    key: 'whatsapp/whatsapp-campaign-report',
    path: `/whatsapp/whatsapp-campaign-report`,
    permissionKeys: ['WHATSAPP_CAMPAIGN']
  },
  {
    key: 'whatsapp/whatsapp-template',
    path: `/whatsapp/whatsapp-template`,
    permissionKeys: ['DEVELOPER']
  },
  {
    key: 'whatsapp/settings',
    path: `/whatsapp/settings`,
    permissionKeys: ['SLACK_SETTINGS']
  }, {
    key: 'whatsapp/api-report',
    path: `/whatsapp/api-report`,
    permissionKeys: ['SLACK_REPORT']
  },{
    key: 'whatsapp/pricing',
    path: `/whatsapp/pricing`,
    permissionKeys: ['WHATSAPP_REPORT']
  },
  {
    key: 'apps-whatsapp-meta',
    path: `/apps-whatsapp-meta`,
    permissionKeys: ['WHATSAPP_SETTINGS', 'DEVELOPER', 'WHATSAPP_CAMPAIGN']
  }, {
    key: 'whatsapp-meta/getting-started',
    path: `/whatsapp-meta/getting-started`,
    permissionKeys: ['DEVELOPER']
  },
  {
    key: 'whatsapp-meta/whatsapp-campaign',
    path: `/whatsapp-meta/whatsapp-campaign`,
    permissionKeys: ['WHATSAPP_CAMPAIGN']
  },
  {
    key: 'whatsapp-meta/manage-whatsapp-campaign',
    path: `/whatsapp-meta/manage-whatsapp-campaign`,
    permissionKeys: ['WHATSAPP_CAMPAIGN']
  },
  {
    key: 'whatsapp-meta/whatsapp-campaign-report',
    path: `/whatsapp-meta/whatsapp-campaign-report`,
    permissionKeys: ['WHATSAPP_CAMPAIGN']
  },
  {
    key: 'whatsapp-meta/whatsapp-meta-template',
    path: `/whatsapp-meta/whatsapp-meta-template`,
    permissionKeys: ['DEVELOPER']
  },
  {
    key: 'whatsapp-meta/settings',
    path: `/whatsapp-meta/settings`,
    permissionKeys: ['SLACK_SETTINGS']
  }, {
    key: 'whatsapp-meta/api-report',
    path: `/whatsapp-meta/api-report`,
    permissionKeys: ['SLACK_REPORT']
  },{
    key: 'whatsapp-meta/pricing',
    path: `/whatsapp-meta/pricing`,
    permissionKeys: ['WHATSAPP_REPORT']
  },
  {
    key: 'chatbot',
    path: `/chatbot`,
    permissionKeys: []
  },
  {
    key: 'importexport',
    path: `/importexport`,
    permissionKeys: []
  },
];

const permissionConfig = [
  ...permissionsMap,
];

const getDefaultPermisionMaps = (): PermissionMap[] => [
  { key: 'dashboard', path: '/dashboard', permissions: ['ALL_PERMISSION'] },
  { key: 'integrations', path: '/integrations', permissions: ['ALL_PERMISSION'] },
  { key: 'importexport', path: '/importexport', permissions: ['ALL_PERMISSION'] },
  { key: 'signin', path: '/signin', permissions: ['ALL_PERMISSION'] },
  { key: 'signup', path: '/signup', permissions: ['ALL_PERMISSION'] },
  { key: 'forgot-password', path: '/forgot-password', permissions: ['ALL_PERMISSION'] },
  { key: 'signup/verify-email', path: '/signup/verify-email', permissions: ['ALL_PERMISSION'] },
  { key: 'set-password/:token', path: '/set-password/:token', permissions: ['ALL_PERMISSION'] },
  { key: 'error/404', path: '/error/404', permissions: ['ALL_PERMISSION'] },
  { key: 'error/403', path: '/error/403', permissions: ['ALL_PERMISSION'] },
  { key: 'error/500', path: '/error/500', permissions: ['ALL_PERMISSION'] },
  { key: 'users/preference/account', path: '/users/preference/account', permissions: ['ALL_PERMISSION'] },
  { key: 'users/preference/change-password', path: '/users/preference/change-password', permissions: ['ALL_PERMISSION'] },
];

const getDefaultPermisionMapsByACL = (acl: AuthListResponse): PermissionMap[] => [
  { key: 'dashboard', path: '/dashboard', permissions: acl.ALL_MODULES },
  { key: 'integrations', path: '/integrations', permissions: acl.ALL_MODULES },
  { key: 'importexport', path: '/importexport', permissions: acl.ALL_MODULES },
  { key: 'signin', path: '/signin', permissions: acl.ALL_MODULES },
  { key: 'signup', path: '/signup', permissions: acl.ALL_MODULES },
  { key: 'forgot-password', path: '/forgot-password', permissions: acl.ALL_MODULES },
  { key: 'signup/verify-email', path: '/signup/verify-email', permissions: acl.ALL_MODULES },
  { key: 'set-password/:token', path: '/set-password/:token', permissions: acl.ALL_MODULES },
  { key: 'error/404', path: '/error/404', permissions: acl.ALL_MODULES },
  { key: 'error/403', path: '/error/403', permissions: acl.ALL_MODULES },
  { key: 'error/500', path: '/error/500', permissions: acl.ALL_MODULES },
  { key: 'users/preference/account', path: '/users/preference/account', permissions: ['ALL_PERMISSION'] },
  { key: 'users/preference/change-password', path: '/users/preference/change-password', permissions: ['ALL_PERMISSION'] },
];
const getPermisionMapsByACL = (acl: AuthListResponse): PermissionMap[] => {
  const allowedPermisions: PermissionMap[] = getDefaultPermisionMapsByACL(acl);

  if (acl.ALL_MODULES && acl.ALL_MODULES.length > 0) {
    allowedPermisions.push({ key: 'apps-contacts', path: '/apps-contacts', permissions: acl.ALL_MODULES });
    allowedPermisions.push({ key: 'contacts/all-contacts', path: '/contacts/all-contacts', permissions: acl.ALL_MODULES });
    allowedPermisions.push({ key: 'contacts/all-contacts/groups', path: '/contacts/all-contacts/groups', permissions: acl.ALL_MODULES });
    allowedPermisions.push({ key: 'contacts/all-contacts/:groupId', path: '/contacts/all-contacts/:groupId', permissions: acl.ALL_MODULES });
    allowedPermisions.push({ key: 'contacts/all-contacts/crm-redirect', path: '/contacts/all-contacts/crm-redirect', permissions: acl.ALL_MODULES });

    allowedPermisions.push({ key: 'apps-developer', path: '/apps-developer', permissions: acl.ALL_MODULES });
    allowedPermisions.push({ key: 'api-tokens', path: '/api-tokens', permissions: acl.ALL_MODULES });
    allowedPermisions.push({ key: 'developer/sandbox', path: '/developer/sandbox', permissions: acl.ALL_MODULES });

    // allowedPermisions.push({ key: 'apps-billing', path: '/apps-billing', permissions: acl.ALL_MODULES });
    // allowedPermisions.push({ key: 'billing/invoice', path: '/billing/invoice', permissions: acl.ALL_MODULES });
    // allowedPermisions.push({ key: 'billing/auto-recharge', path: '/billing/auto-recharge', permissions: acl.ALL_MODULES });
    // allowedPermisions.push({ key: 'billing/payment', path: '/billing/payment', permissions: acl.ALL_MODULES });
    // allowedPermisions.push({ key: 'billing/payment/success', path: '/billing/payment/success', permissions: acl.ALL_MODULES });
    // allowedPermisions.push({ key: 'billing/payment/failure', path: '/billing/payment/failure', permissions: acl.ALL_MODULES });


    allowedPermisions.push({ key: 'apps-sms', path: '/apps-sms', permissions: acl.ALL_MODULES });
    // allowedPermisions.push({ key: 'sms/getting-started', path: '/sms/getting-started', permissions: acl.ALL_MODULES });
    // allowedPermisions.push({ key: 'sms/settings', path: '/sms/settings', permissions: acl.ALL_MODULES });
    allowedPermisions.push({ key: 'sms/campaign', path: '/sms/campaign', permissions: acl.ALL_MODULES });
    allowedPermisions.push({ key: 'sms/campaign/:id', path: '/sms/campaign/:id', permissions: acl.ALL_MODULES });
    allowedPermisions.push({ key: 'sms/manage-campaign', path: '/sms/manage-campaign', permissions: acl.ALL_MODULES });
    allowedPermisions.push({ key: 'sms/campaign-report', path: '/sms/campaign-report', permissions: acl.ALL_MODULES });
    allowedPermisions.push({ key: 'sms/api-report', path: '/sms/api-report', permissions: acl.ALL_MODULES });
    // allowedPermisions.push({ key: 'sms/scheduled-messages', path: '/sms/scheduled-messages', permissions: acl.ALL_MODULES });
    allowedPermisions.push({ key: 'sms/api-logs', path: '/sms/api-logs', permissions: acl.ALL_MODULES });
    // allowedPermisions.push({ key: 'sms/pricing', path: '/sms/pricing', permissions: acl.ALL_MODULES });
    allowedPermisions.push({ key: 'sms/template', path: '/sms/template', permissions: acl.ALL_MODULES });

    // allowedPermisions.push({ key: 'apps-viber', path: '/apps-viber', permissions: acl.ALL_MODULES });
    // allowedPermisions.push({ key: 'viber/getting-started', path: '/viber/getting-started', permissions: acl.ALL_MODULES });
    // allowedPermisions.push({ key: 'viber/settings', path: '/viber/settings', permissions: acl.ALL_MODULES });
    // allowedPermisions.push({ key: 'viber/api-report', path: '/viber/api-report', permissions: acl.ALL_MODULES });
    // allowedPermisions.push({ key: 'viber/api-logs', path: '/viber/api-logs', permissions: acl.ALL_MODULES });
    // allowedPermisions.push({ key: 'viber/pricing', path: '/viber/pricing', permissions: acl.ALL_MODULES });
    //
    // allowedPermisions.push({ key: 'apps-verify', path: '/apps-verify', permissions: acl.ALL_MODULES });
    // allowedPermisions.push({ key: 'verify/getting-started', path: '/verify/getting-started', permissions: acl.ALL_MODULES });
    // allowedPermisions.push({ key: 'verify/settings', path: '/verify/settings', permissions: acl.ALL_MODULES });
    // allowedPermisions.push({ key: 'verify/report', path: '/sms/report', permissions: acl.ALL_MODULES });
    // allowedPermisions.push({ key: 'verify/api-logs', path: '/verify/api-logs', permissions: acl.ALL_MODULES });

    allowedPermisions.push({ key: 'users/preference/sub-users', path: '/users/preference/sub-users', permissions: acl.ALL_MODULES });
    allowedPermisions.push({ key: 'users/preference/sub-accounts', path: '/users/preference/sub-accounts', permissions: acl.ALL_MODULES });

    // allowedPermisions.push({key: 'apps-slack', path: '/apps-slack', permissions: acl.ALL_MODULES});
    // allowedPermisions.push({key: 'slack/getting-started', path: '/slack/getting-started', permissions: acl.ALL_MODULES});
    // allowedPermisions.push({key: 'slack/workspace-details', path: '/slack/workspace-details', permissions: acl.ALL_MODULES});
    // allowedPermisions.push({key: 'slack/settings', path: '/slack/settings', permissions: acl.ALL_MODULES});
    // allowedPermisions.push({key: 'slack/api-report', path: '/slack/api-report', permissions: acl.ALL_MODULES});
    // allowedPermisions.push({key: 'slack/slack-redirect', path: '/slack/slack-redirect', permissions: acl.ALL_MODULES});
    //
    // allowedPermisions.push({ key: 'apps-numlookup', path: '/apps-numlookup', permissions: acl.ALL_MODULES });
    // allowedPermisions.push({ key: 'numlookup/getting-started', path: '/numlookup/getting-started', permissions: acl.ALL_MODULES });
    // allowedPermisions.push({ key: 'numlookup/api-report', path: '/numlookup/api-report', permissions: acl.ALL_MODULES });
    // allowedPermisions.push({ key: 'numlookup/pricing', path: '/numlookup/pricing', permissions: acl.ALL_MODULES });


    // allowedPermisions.push({key: 'apps-whatsapp', path: '/apps-whatsapp', permissions: acl.ALL_MODULES});
    // allowedPermisions.push({key: 'whatsapp/getting-started', path: '/whatsapp/getting-started', permissions: acl.ALL_MODULES});
    // allowedPermisions.push({key: 'whatsapp/whatsapp-campaign', path: '/whatsapp/whatsapp-campaign', permissions: acl.ALL_MODULES});
    // allowedPermisions.push({ key: 'whatsapp/whatsapp-campaign/:id', path: '/whatsapp/whatsapp-campaign/:id', permissions: acl.ALL_MODULES });
    // allowedPermisions.push({key: 'whatsapp/manage-whatsapp-campaign', path: '/whatsapp/manage-whatsapp-campaign', permissions: acl.ALL_MODULES});
    // allowedPermisions.push({key: 'whatsapp/whatsapp-campaign-report', path: '/whatsapp/whatsapp-campaign-report', permissions: acl.ALL_MODULES});
    // allowedPermisions.push({key: 'whatsapp/whatsapp-template', path: '/whatsapp/whatsapp-template', permissions: acl.ALL_MODULES});
    // allowedPermisions.push({key: 'whatsapp/settings', path: '/whatsapp/settings', permissions: acl.ALL_MODULES});
    // allowedPermisions.push({key: 'whatsapp/api-report', path: '/whatsapp/api-report', permissions: acl.ALL_MODULES});
    // allowedPermisions.push({key: 'whatsapp/pricing', path: '/whatsapp/pricing', permissions: acl.ALL_MODULES });
    // allowedPermisions.push({key: 'chatbot', path: '/chatbot', permissions: acl.WHATSAPP_CHATBOT});
    // allowedPermisions.push({key: 'chatbot/:name/:id', path: '/chatbot/:name/:id',permissions: acl.WHATSAPP_CHATBOT});
    //
    // allowedPermisions.push({key: 'apps-whatsapp-meta', path: '/apps-whatsapp-meta', permissions: acl.ALL_MODULES});
    // // allowedPermisions.push({key: 'whatsapp-meta/connect-whatsapp', path: '/whatsapp-meta/connect-whatsapp', permissions: acl.ALL_MODULES });
    // allowedPermisions.push({key: 'whatsapp-meta/getting-started', path: '/whatsapp-meta/getting-started', permissions: acl.ALL_MODULES });
    // allowedPermisions.push({key: 'whatsapp-meta/whatsapp-campaign', path: '/whatsapp-meta/whatsapp-campaign', permissions: acl.ALL_MODULES});
    // allowedPermisions.push({ key: 'whatsapp-meta/whatsapp-campaign/:id', path: '/whatsapp-meta/whatsapp-campaign/:id', permissions: acl.ALL_MODULES });
    // allowedPermisions.push({key: 'whatsapp-meta/manage-whatsapp-campaign', path: '/whatsapp-meta/manage-whatsapp-campaign', permissions: acl.ALL_MODULES});
    // allowedPermisions.push({key: 'whatsapp-meta/whatsapp-campaign-report', path: '/whatsapp-meta/whatsapp-campaign-report', permissions: acl.ALL_MODULES});
    // allowedPermisions.push({key: 'whatsapp-meta/whatsapp-meta-template', path: '/whatsapp-meta/whatsapp-meta-template', permissions: acl.ALL_MODULES});
    // allowedPermisions.push({key: 'whatsapp-meta/settings', path: '/whatsapp-meta/settings', permissions: acl.ALL_MODULES});
    // allowedPermisions.push({key: 'whatsapp-meta/api-report', path: '/whatsapp-meta/api-report', permissions: acl.ALL_MODULES});
    // allowedPermisions.push({key: 'whatsapp-meta/pricing', path: '/whatsapp-meta/pricing', permissions: acl.ALL_MODULES });
    // allowedPermisions.push({ key: 'whatsapp-meta/connect-whatsapp/success', path: '/whatsapp-meta/connect-whatsapp/success', permissions: acl.ALL_MODULES });
    // allowedPermisions.push({ key: 'whatsapp-meta/connect-whatsapp/failure', path: '/whatsapp-meta/connect-whatsapp/failure', permissions: acl.ALL_MODULES });
    // allowedPermisions.push({key: 'whatsapp-meta/registered-numbers/:id', path: '/whatsapp-meta/registered-numbers/:id', permissions: acl.ALL_MODULES});
    // allowedPermisions.push({key: 'whatsapp-meta/registered-numbers', path: '/whatsapp-meta/registered-numbers', permissions: acl.ALL_MODULES});
    // allowedPermisions.push({key: 'whatsapp-meta/meta-chatbot', path: '/whatsapp-meta/meta-chatbot', permissions: acl.ALL_MODULES});
    // allowedPermisions.push({key: 'whatsapp-meta/meta-chatbot/:name/:id', path: '/whatsapp-meta/meta-chatbot/:name/:id',permissions: acl.ALL_MODULES});

    allowedPermisions.push({ key: 'apps-integrations', path: '/apps-integrations', permissions: acl.ALL_MODULES });
    allowedPermisions.push({ key: 'integrations/crm-redirect', path: '/integrations/crm-redirect', permissions: acl.ALL_MODULES });
    allowedPermisions.push({ key: 'integrations/hubspot', path: '/integrations/hubspot', permissions: acl.ALL_MODULES });
    allowedPermisions.push({ key: 'integrations/zoho', path: '/integrations/zoho', permissions: acl.ALL_MODULES });
  } else {
    // let billingPermissoion = false;
    let smsPermissoion = false;
    // let verifyPermissoion = false;
    // let viberPermission = false;
    // let slackPermission = false;
    // let numlookupPermission = false;
    // let whatsappPermission = false;
    // let karixWhatsappPermission = false;

    // if (acl.BILLING_INVOICE && acl.BILLING_INVOICE.length > 0) {
    //   billingPermissoion = true;
    //   allowedPermisions.push({ key: 'billing/invoice', path: '/billing/invoice', permissions: acl.BILLING_INVOICE });
    //
    // }
    // if (acl.BILLING_PAYMENT && acl.BILLING_PAYMENT.length > 0) {
    //   billingPermissoion = true;
    //   allowedPermisions.push({ key: 'billing/payment', path: '/billing/payment', permissions: acl.BILLING_PAYMENT });
    //   allowedPermisions.push({ key: 'billing/payment/success', path: '/billing/payment/success', permissions: acl.BILLING_PAYMENT });
    //   allowedPermisions.push({ key: 'billing/payment/failure', path: '/billing/payment/failure', permissions: acl.BILLING_PAYMENT });
    // }
    // if (acl.BILLING_AUTO_RECHARGE && acl.BILLING_AUTO_RECHARGE.length > 0) {
    //   billingPermissoion = true;
    //   allowedPermisions.push({ key: 'billing/auto-recharge', path: '/billing/auto-recharge', permissions: acl.BILLING_AUTO_RECHARGE });
    // }
    // if (billingPermissoion) {
    //   allowedPermisions.push({ key: 'apps-billing', path: '/apps-billing', permissions: acl.ALL_MODULES });
    // }

    if (acl.CONTACTS && acl.CONTACTS.length > 0) {
      allowedPermisions.push({ key: 'apps-contacts', path: '/apps-contacts', permissions: acl.ALL_MODULES });
      allowedPermisions.push({ key: 'contacts/all-contacts', path: '/contacts/all-contacts', permissions: acl.CONTACTS });
      allowedPermisions.push({ key: 'contacts/all-contacts/groups', path: '/contacts/all-contacts/groups', permissions: acl.CONTACTS });
      allowedPermisions.push({ key: 'contacts/all-contacts/:groupId', path: '/contacts/all-contacts/:groupId', permissions: acl.ALL_MODULES });
      allowedPermisions.push({ key: 'contacts/all-contacts/crm-redirect', path: '/contacts/all-contacts/crm-redirect', permissions: acl.CONTACTS });
      allowedPermisions.push({ key: 'apps-integrations', path: '/apps-integrations', permissions: acl.ALL_MODULES });
      allowedPermisions.push({ key: 'integrations/crm-redirect', path: '/integrations/crm-redirect', permissions: acl.ALL_MODULES });
      allowedPermisions.push({ key: 'integrations/hubspot', path: '/integrations/hubspot', permissions: acl.ALL_MODULES });
      allowedPermisions.push({ key: 'integrations/zoho', path: '/integrations/zoho', permissions: acl.ALL_MODULES });
    }
    if (acl.DEVELOPER && acl.DEVELOPER.length > 0) {
      smsPermissoion = true;
      // verifyPermissoion = true;
      // viberPermission = true;

      allowedPermisions.push({ key: 'apps-developer', path: '/apps-developer', permissions: acl.DEVELOPER });
      allowedPermisions.push({ key: 'api-tokens', path: '/api-tokens', permissions: acl.DEVELOPER });
      allowedPermisions.push({ key: 'developer/sandbox', path: '/developer/sandbox', permissions: acl.DEVELOPER });

      // allowedPermisions.push({ key: 'sms/getting-started', path: '/sms/getting-started', permissions: acl.DEVELOPER });
      allowedPermisions.push({ key: 'sms/api-logs', path: '/sms/api-logs', permissions: acl.DEVELOPER });

      allowedPermisions.push({ key: 'viber/getting-started', path: '/viber/getting-started', permissions: acl.ALL_MODULES });
      allowedPermisions.push({ key: 'viber/api-logs', path: '/viber/api-logs', permissions: acl.ALL_MODULES });

      allowedPermisions.push({ key: 'verify/getting-started', path: '/verify/getting-started', permissions: acl.DEVELOPER });
      allowedPermisions.push({ key: 'verify/api-logs', path: '/verify/api-logs', permissions: acl.DEVELOPER });

      allowedPermisions.push({key: 'slack/getting-started', path: '/slack/getting-started', permissions: acl.ALL_MODULES});
      allowedPermisions.push({key: 'slack/workspace-details', path: '/slack/workspace-details', permissions: acl.ALL_MODULES});
      allowedPermisions.push({ key: 'slack/slack-redirect', path: '/slack/slack-redirect', permissions: acl.ALL_MODULES });

      allowedPermisions.push({ key: 'numlookup/getting-started', path: '/numlookup/getting-started', permissions: acl.ALL_MODULES });


      // allowedPermisions.push({key: 'whatsapp-meta/connect-whatsapp', path: '/whatsapp-meta/connect-whatsapp', permissions: acl.ALL_MODULES });
      // allowedPermisions.push({key: 'whatsapp-meta/registered-numbers', path: '/whatsapp-meta/registered-numbers', permissions: acl.ALL_MODULES});
      // allowedPermisions.push({key: 'whatsapp-meta/registered-numbers/:id', path: '/whatsapp-meta/registered-numbers/:id', permissions: acl.ALL_MODULES});
      // allowedPermisions.push({ key: 'whatsapp-meta/connect-whatsapp/success', path: '/whatsapp-meta/connect-whatsapp/success', permissions: acl.ALL_MODULES });
      // allowedPermisions.push({ key: 'whatsapp-meta/connect-whatsapp/failure', path: '/whatsapp-meta/connect-whatsapp/failure', permissions: acl.ALL_MODULES });
      // allowedPermisions.push({key: 'whatsapp-meta/getting-started', path: '/whatsapp-meta/getting-started', permissions: acl.ALL_MODULES});
      // allowedPermisions.push({key: 'whatsapp-meta/whatsapp-meta-template', path: '/whatsapp-meta/whatsapp-meta-template', permissions: acl.ALL_MODULES});
    }

    // if (acl.SMS_SETTINGS && acl.SMS_SETTINGS.length > 0) {
    //   smsPermissoion = true;
    //   allowedPermisions.push({ key: 'sms/settings', path: '/sms/settings', permissions: acl.SMS_SETTINGS });
    // }

    if (acl.SMS_CAMPAIGN && acl.SMS_CAMPAIGN.length > 0) {
      smsPermissoion = true;
      allowedPermisions.push({ key: 'sms/campaign', path: '/sms/campaign', permissions: acl.SMS_CAMPAIGN });
      allowedPermisions.push({ key: 'sms/campaign/:id', path: '/sms/campaign/:id', permissions: acl.SMS_CAMPAIGN });
      allowedPermisions.push({ key: 'sms/manage-campaign', path: '/sms/manage-campaign', permissions: acl.SMS_CAMPAIGN });
      allowedPermisions.push({ key: 'sms/campaign-report', path: '/sms/campaign-report', permissions: acl.SMS_CAMPAIGN });
      allowedPermisions.push({ key: 'sms/template', path: '/sms/template', permissions: acl.SMS_CAMPAIGN });
    }

    if (acl.SMS_TEMPLATE && acl.SMS_TEMPLATE.length > 0) {
      smsPermissoion = true;
      allowedPermisions.push({ key: 'sms/template', path: '/sms/template', permissions: acl.SMS_TEMPLATE });
    }

    if (acl.SMS_REPORT && acl.SMS_REPORT.length > 0) {
      smsPermissoion = true;
      // allowedPermisions.push({ key: 'sms/pricing', path: '/sms/pricing', permissions: acl.SMS_REPORT });
      allowedPermisions.push({ key: 'sms/api-report', path: '/sms/api-report', permissions: acl.SMS_REPORT });
      // allowedPermisions.push({ key: 'sms/scheduled-messages', path: '/sms/scheduled-messages', permissions: acl.SMS_REPORT });
    }
    if (smsPermissoion) {
      allowedPermisions.push({ key: 'apps-sms', path: '/apps-sms', permissions: acl.ALL_MODULES });
    }

    // if (acl.SLACK_CONFIG && acl.SLACK_CONFIG.length > 0) {
    //   slackPermission = true;
    //   allowedPermisions.push({key: 'slack/settings', path: '/slack/settings', permissions: acl.SLACK_CONFIG});
    // }
    //
    // if (acl.SLACK_REPORT && acl.SLACK_REPORT.length > 0) {
    //   slackPermission = true;
    //   allowedPermisions.push({key: 'slack/api-report', path: '/slack/api-report', permissions: acl.SLACK_REPORT});
    // }
    //
    // if (slackPermission) {
    //   allowedPermisions.push({key: 'apps-slack', path: '/apps-slack', permissions: acl.ALL_MODULES});
    // }
    //
    // if (acl.VERIFY_SETTINGS && acl.VERIFY_SETTINGS.length > 0) {
    //   verifyPermissoion = true;
    //   allowedPermisions.push({ key: 'verify/settings', path: '/verify/settings', permissions: acl.VERIFY_SETTINGS });
    // }
    //
    // if (acl.VERIFY_REPORT && acl.VERIFY_REPORT.length > 0) {
    //   verifyPermissoion = true;
    //   allowedPermisions.push({ key: 'verify/report', path: '/verify/report', permissions: acl.VERIFY_REPORT });
    // }
    // if (verifyPermissoion) {
    //   allowedPermisions.push({ key: 'apps-verify', path: '/apps-verify', permissions: acl.ALL_MODULES });
    // }
    //
    // if (acl.VIBER_CONFIG && acl.VIBER_CONFIG.length > 0) {
    //   viberPermission = true;
    //   allowedPermisions.push({ key: 'viber/settings', path: '/viber/settings', permissions: acl.VIBER_CONFIG });
    // }
    //
    // if (acl.VIBER_REPORT && acl.VIBER_REPORT.length > 0) {
    //   viberPermission = true;
    //   allowedPermisions.push({ key: 'viber/api-report', path: '/viber/api-report', permissions: acl.VIBER_REPORT });
    //   allowedPermisions.push({ key: 'viber/pricing', path: '/viber/pricing', permissions: acl.VIBER_REPORT });
    // }
    // if (viberPermission) {
    //   allowedPermisions.push({ key: 'apps-viber', path: '/apps-viber', permissions: acl.ALL_MODULES });
    // }
    //
    // if (acl.NUMLOOKUP_REPORT && acl.NUMLOOKUP_REPORT.length > 0) {
    //   numlookupPermission = true;
    //   allowedPermisions.push({key: 'numlookup/api-report', path: '/numlookup/api-report', permissions: acl.NUMLOOKUP_REPORT});
    //   allowedPermisions.push({ key: 'numlookup/pricing', path: '/numlookup/pricing', permissions: acl.NUMLOOKUP_REPORT });
    // }
    //
    // if (numlookupPermission) {
    //   allowedPermisions.push({ key: 'apps-numlookup', path: '/apps-numlookup', permissions: acl.ALL_MODULES });
    // }
    //
    // if (acl.WHATSAPP_CONFIG && acl.WHATSAPP_CONFIG.length > 0) {
    //   whatsappPermission = true;
    //   allowedPermisions.push({key: 'whatsapp-meta/settings', path: '/whatsapp-meta/settings', permissions: acl.WHATSAPP_CONFIG});
    // }
    //
    // if (acl.WHATSAPP_REPORT && acl.WHATSAPP_REPORT.length > 0) {
    //   whatsappPermission = true;
    //   allowedPermisions.push({key: 'whatsapp-meta/api-report', path: '/whatsapp-meta/api-report', permissions: acl.WHATSAPP_REPORT});
    //   // allowedPermisions.push({key: 'whatsapp-meta/pricing', path: '/whatsapp-meta/pricing', permissions: acl.ALL_MODULES });
    // }
    //
    // if (acl.WHATSAPP_CAMPAIGN && acl.WHATSAPP_CAMPAIGN.length > 0) {
    //   whatsappPermission = true;
    //   allowedPermisions.push({ key: 'whatsapp-meta/whatsapp-campaign', path: '/whatsapp-meta/whatsapp-campaign', permissions: acl.WHATSAPP_CAMPAIGN });
    //   allowedPermisions.push({ key: 'whatsapp-meta/whatsapp-campaign/:id', path: '/whatsapp-meta/whatsapp-campaign/:id', permissions: acl.WHATSAPP_CAMPAIGN });
    //   allowedPermisions.push({ key: 'whatsapp-meta/manage-whatsapp-campaign', path: '/whatsapp-meta/manage-whatsapp-campaign', permissions: acl.WHATSAPP_CAMPAIGN });
    //   allowedPermisions.push({ key: 'whatsapp-meta/whatsapp-campaign-report', path: '/whatsapp-meta/whatsapp-campaign-report', permissions: acl.WHATSAPP_CAMPAIGN });
    // }
    //
    // if (acl.WHATSAPP_CHATBOT && acl.WHATSAPP_CHATBOT.length > 0){
    //   whatsappPermission = true;
    //   allowedPermisions.push({key: 'whatsapp-meta/meta-chatbot', path: '/whatsapp-meta/meta-chatbot', permissions: acl.WHATSAPP_CHATBOT});
    //   allowedPermisions.push({key: 'whatsapp-meta/meta-chatbot/:name/:id', path: '/whatsapp-meta/meta-chatbot/:name/:id',permissions: acl.WHATSAPP_CHATBOT});
    // }
    //
    // if (acl.KARIX_WHATSAPP && acl.KARIX_WHATSAPP.length > 0){
    //   karixWhatsappPermission=true;
    // }
    // if (karixWhatsappPermission){
    //   allowedPermisions.push({key: 'whatsapp/getting-started', path: '/whatsapp/getting-started', permissions: acl.ALL_MODULES});
    //   allowedPermisions.push({key: 'whatsapp/whatsapp-template', path: '/whatsapp/whatsapp-template', permissions: acl.ALL_MODULES});
    //   allowedPermisions.push({ key: 'whatsapp/whatsapp-campaign', path: '/whatsapp/whatsapp-campaign', permissions: acl.WHATSAPP_CAMPAIGN });
    //   allowedPermisions.push({ key: 'whatsapp/whatsapp-campaign/:id', path: '/whatsapp/whatsapp-campaign/:id', permissions: acl.WHATSAPP_CAMPAIGN });
    //   allowedPermisions.push({ key: 'whatsapp/manage-whatsapp-campaign', path: '/whatsapp/manage-whatsapp-campaign', permissions: acl.WHATSAPP_CAMPAIGN });
    //   allowedPermisions.push({ key: 'whatsapp/whatsapp-campaign-report', path: '/whatsapp/whatsapp-campaign-report', permissions: acl.WHATSAPP_CAMPAIGN });
    //
    //   allowedPermisions.push({key: 'chatbot', path: '/chatbot', permissions: acl.WHATSAPP_CHATBOT});
    //   allowedPermisions.push({key: 'chatbot/:name/:id', path: '/chatbot/:name/:id',permissions: acl.WHATSAPP_CHATBOT});
    //
    //   allowedPermisions.push({key: 'whatsapp/settings', path: '/whatsapp/settings', permissions: acl.ALL_MODULES});
    //   allowedPermisions.push({key: 'whatsapp/api-report', path: '/whatsapp/api-report', permissions: acl.ALL_MODULES});
    //   allowedPermisions.push({key: 'apps-whatsapp', path: '/apps-whatsapp', permissions: acl.ALL_MODULES});
    //   allowedPermisions.push({key: 'whatsapp/pricing', path: '/whatsapp/pricing', permissions: acl.ALL_MODULES });
    // }
    // else if (whatsappPermission) {
    //     allowedPermisions.push({key: 'apps-whatsapp-meta', path: '/apps-whatsapp-meta', permissions: acl.ALL_MODULES});
    //   }

    if (acl.SUB_ACCOUNT && acl.SUB_ACCOUNT.length > 0) {
      allowedPermisions.push({ key: 'users/preference/sub-accounts', path: '/users/preference/sub-accounts', permissions: acl.SUB_ACCOUNT });
    }
    if (acl.SUB_USER && acl.SUB_USER.length > 0) {
      allowedPermisions.push({ key: 'users/preference/sub-users', path: '/users/preference/sub-users', permissions: acl.SUB_USER });
    }
  }
  return allowedPermisions;
};
export {
  permissionConfig,
  getDefaultPermisionMaps,
  getDefaultPermisionMapsByACL,
  getPermisionMapsByACL
};
